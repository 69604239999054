import {yupResolver} from '@hookform/resolvers/yup';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import {Theme, useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {useCallable, useGroups} from '@ozark/common';
import {emptyStringToNull} from '@ozark/common/helpers';
import firebase from 'firebase/compat/app';
import {useState} from 'react';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {useNotification} from '../../hooks/useNotification';
import {AgentForm, AgentFormModel} from './AgentForm';
import {getAgentPhoneSchema} from './phoneValidator';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      [theme.breakpoints.up('md')]: {
        width: 600,
      },
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

const schema = yup.object().shape(
  {
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required'),
    groupId: yup.string().required('Group is required'),
    email: yup.string().required('Email is required'),
    role: yup.string().required('Role is required'),
    phone: getAgentPhoneSchema(),
    agentCode: yup.string().required('Agent Code is required'),
    dateOfBirth: yup.date().nullable().transform(emptyStringToNull).typeError('Invalid date'),
    adminContact: yup.object().shape({
      email: yup.string().email('Invalid email'),
    }),
  },
  [['phone', 'phone']]
);

export const CreateDialog = ({onClose}: {onClose: () => void}) => {
  const classes = useStyles();
  const theme = useTheme();
  const {
    documents: {data: groups},
  } = useGroups();
  const {createAgent} = useCallable();
  const [loading, setLoading] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const showNotification = useNotification();

  const hookForm = useForm<AgentFormModel>({
    resolver: yupResolver(schema) as any,
    shouldUnregister: true,
  });
  const {handleSubmit} = hookForm;

  const onSuccess = async (data: any) => {
    if (!groups) {
      return;
    }

    const {groupId, dateOfBirth, phone, ...dataWithoutGroupId} = data;

    try {
      setLoading(true);
      const groupObject = groups.find(g => g.id === groupId);
      const group = groupObject && {
        id: groupObject.id,
        name: groupObject.name,
        logoUrl: groupObject.logoUrl,
      };
      const payload = {
        ...dataWithoutGroupId,
        group,
        dateOfBirth: dateOfBirth && firebase.firestore.Timestamp.fromDate(dateOfBirth),
      };
      if (!!phone) {
        payload.phone = phone;
      }
      const result = await createAgent(payload);

      if (result.status === 'ok') {
        showNotification('success', 'User successfully created.');
        setLoading(false);
        onClose();
        return;
      }

      throw Error(result.message);
    } catch (err) {
      showNotification('error', (err as Error).message);
      setLoading(false);
    }
  };

  const onError = (_data: any) => {
    setLoading(false);
  };

  return (
    <Dialog open aria-labelledby="create-dialog-title" fullScreen={fullScreen} maxWidth={'lg'}>
      <DialogTitle id="create-dialog-title">Create Agent</DialogTitle>
      <DialogContent>
        <div className={classes.content}>
          <Grid container spacing={1}>
            <AgentForm hookForm={hookForm} isAuthUserAdmin />
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit(onSuccess, onError)} color="primary" disabled={loading}>
          {loading && <CircularProgress className={classes.buttonProgress} size={24} />}
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
