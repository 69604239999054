import {Box, Divider, Stack} from '@mui/material';
import {AgentView, Dispositions, ListOption, SearchCriteria, useApiContainer} from '@ozark/common';
import {BoxParentHeight, ButtonExportCsv, percentFormatter, Table} from '@ozark/common/components';
import {
  DispositionStatistic,
  PaginatedResponse,
} from '@ozark/functions/src/functions/express/private/types';
import {DispositionStatisticFields} from '@ozark/functions/src/functions/express/private/types/DispositionStatistic';
import {useEffect, useState} from 'react';
import {Column} from '../../../api/Column';
import {useAgents} from '../../../hooks/useAgents';
import {LoadingStatus} from '../../Analytics/common/LoadingStatus';
import {AgentSelect} from '../../Filters/AgentSelect';
import {SelectOptions} from '../../Filters/SelectOption';

export const DEFAULT_SEARCH_CRITERIA: SearchCriteria = {
  limit: 20, // page size
  offset: 1, // page
  order: 'asc',
  orderBy: DispositionStatisticFields.agent,
};

const DispositionStatisticOptions: {[_: string]: string} = {
  [Dispositions.asReceived]: Dispositions.asReceived,
  [Dispositions.uwApproved]: Dispositions.uwApproved,
  [Dispositions.boarded]: 'UW C Approved',
  [Dispositions.uwPending]: Dispositions.uwPending,
  [Dispositions.uwDeclined]: Dispositions.uwDeclined,
  [Dispositions.uwWithdrawn]: Dispositions.uwWithdrawn,
};

export const AgentStatisticsDispositionStatisticsPage = () => {
  const apiClient = useApiContainer();
  const [dispositionStatisticReport, setDispositionStatisticReport] =
    useState<PaginatedResponse<DispositionStatistic> | null>(null);
  const [loading, setLoading] = useState(true);
  const [selectedAgent, setSelectedAgent] = useState<AgentView>();
  const {agents} = useAgents();
  const dispositionOptions = Object.entries(DispositionStatisticOptions).map(
    ([key, value]) => ({key, value} as ListOption)
  );
  const [disposition, setDisposition] = useState<ListOption>(dispositionOptions[0]);
  const [searchCriteria, setSearchCriteria] = useState<SearchCriteria>(DEFAULT_SEARCH_CRITERIA);

  useEffect(() => {
    const getDispositionStatisticReport = async () => {
      setLoading(true);
      try {
        const report = await apiClient?.agentStatistics.getDispositionStatistics(
          searchCriteria,
          disposition.key,
          selectedAgent?.id
        );
        setDispositionStatisticReport(report || null);
      } catch (error) {
        setDispositionStatisticReport(null);
      } finally {
        setLoading(false);
      }
    };

    getDispositionStatisticReport();
  }, [selectedAgent, disposition, searchCriteria]);

  const handleRetrieveData = (searchCriteria: SearchCriteria) => {
    setSearchCriteria(searchCriteria);
  };

  const hasData = Boolean(dispositionStatisticReport && dispositionStatisticReport.data.length);
  const columns = getColumsConfig(disposition);

  return (
    <Stack flex={1} pb={1}>
      <Box display="flex" alignItems="center">
        <Box flex={1} />
        <AgentSelect
          agents={agents}
          onAgentSelect={setSelectedAgent}
          selectedAgent={selectedAgent}
        />
        <Divider orientation="vertical" flexItem light sx={{mx: 2}} />
        <SelectOptions
          options={dispositionOptions}
          onOptionSelect={(value?: ListOption) => setDisposition(value ?? dispositionOptions[0])}
          selectedOption={disposition}
        />
        <Divider orientation="vertical" flexItem light sx={{mx: 2}} />
        <ButtonExportCsv
          filename="agent-statistics-dispositions"
          rows={dispositionStatisticReport?.data}
          columnsConfig={columns}
        />
      </Box>
      {!loading && hasData && (
        <BoxParentHeight my={2}>
          <Table
            columns={columns}
            data={dispositionStatisticReport!}
            onRetrieveData={handleRetrieveData}
            paginate
            stickyHeader
          />
        </BoxParentHeight>
      )}
      <LoadingStatus loading={loading || agents.promised} hasData={hasData} />
    </Stack>
  );
};

const getColumsConfig = (disposition: ListOption): Column<DispositionStatistic>[] => [
  {
    id: DispositionStatisticFields.agent,
    numeric: false,
    sortable: true,
    label: 'Agent Name',
    width: 120,
    export: true,
  },
  {
    id: DispositionStatisticFields.count,
    numeric: true,
    sortable: true,
    label: `${disposition.value} Count`,
    export: true,
  },
  {
    id: DispositionStatisticFields.countOfTotal,
    numeric: true,
    sortable: true,
    label: `Count % of Total ${disposition.value}`,
    selector: row => percentFormatter.format(row.countOfTotal),
    export: true,
  },
  {
    id: DispositionStatisticFields.volume,
    numeric: true,
    sortable: true,
    label: `${disposition.value} Volume`,
    export: true,
  },
  {
    id: DispositionStatisticFields.volumeOfTotal,
    numeric: true,
    sortable: true,
    label: `Volume % of Total ${disposition.value}`,
    selector: row => percentFormatter.format(row.volumeOfTotal),
    export: true,
  },
];
