import {AsyncState, Ticket, TicketStatus} from '../../..';

export const FIELD_NAME_SUBJECT = 'subject';
export const FIELD_NAME_DESCRIPTION = 'description';
export const FIELD_NAME_ASSIGNEE = 'assignee';
export const FIELD_NAME_ASSOCIATION = 'association';
export const FIELD_NAME_CATEGORY = 'category';
export const FIELD_NAME_AUTHOR = 'author';
export const FIELD_NAME_STATUS = 'status';
export const FIELD_NAME_DISTINGUISHABLE_ID = 'distinguishableId';
export const FIELD_NAME_VIEWABLE_BY = 'viewableBy';

// assignee values
export const FIELD_NAME_TYPE = 'type';
export const FIELD_NAME_ERP_ROLE = 'erpRole';
export const FIELD_NAME_ERP_DEPARTMENT = 'erpDepartment';
export const FIELD_NAME_ERP_USER = 'erpUser';
export const FIELD_NAME_GROUP = 'group';
export const FIELD_NAME_AGENT = 'agent';
export const FIELD_NAME_MERCHANT = 'merchant';
export const FIELD_NAME_MID = 'mid';
export const FIELD_NAME_LABELS = 'labels';
export const FIELD_NAME_EVENTS = 'events';
export const FIELD_NAME_APPLICATION = 'application';

export const TicketStatusNames: Record<TicketStatus, string> = {
  [TicketStatus.Draft]: 'Draft',
  [TicketStatus.Open]: 'New',
  [TicketStatus.InProgress]: 'In Progress',
  [TicketStatus.Closed]: 'Resolved',
  [TicketStatus.Unresolved]: 'Unresolved',
  [TicketStatus.Deleted]: 'Trash',
};

export const TicketStatusRules: Record<TicketStatus, TicketStatus[]> = {
  draft: [TicketStatus.Open],
  open: [TicketStatus.InProgress],
  doing: [TicketStatus.Closed, TicketStatus.Unresolved],
  closed: [TicketStatus.Open, TicketStatus.InProgress],
  unresolved: [TicketStatus.Open, TicketStatus.InProgress],
  deleted: [TicketStatus.Open, TicketStatus.InProgress],
};

export const statePromised: AsyncState<Ticket[]> = {
  promised: true,
  data: [],
};
export const stateEmpty: AsyncState<Ticket[]> = {
  promised: false,
  data: [],
};

export const isAllTicketsViewStorageName = 'isAllTicketsView';
