import {Button} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useCallback} from 'react';

export const useNotification = () => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const showNotification = useCallback((variant: 'success' | 'error' | 'info', message: string) => {
    enqueueSnackbar(message.toString(), {
      preventDuplicate: true,
      variant,
      persist: variant === 'error',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      action: key => (
        <Button style={{color: '#FFFFFF'}} onClick={() => closeSnackbar(key)}>
          Close
        </Button>
      ),
    });
  }, []);

  return showNotification;
};
