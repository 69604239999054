export enum Department {
  administrative = 'Administrative',
  agentSupport = 'Agent Support',
  underwriting = 'Underwriting',
  customerService = 'Customer Service',
  helpDesk = 'Help Desk',
  logistics = 'Logistics',
  finance = 'Finance',
  risk = 'Risk',
  marketing = 'Marketing',
  sales = 'Sales',
}
