import AllInboxIcon from '@mui/icons-material/AllInbox';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import EmailIcon from '@mui/icons-material/Email';
import FolderDeleteIcon from '@mui/icons-material/FolderDelete';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import Update from '@mui/icons-material/Update';
import {TicketStatus} from '../../..';
import {TicketStatusNames} from '../constants/constants';
import {FilterKey, TFolder, TicketsFolderId} from '../types';

export const ticketStatusFolders: TFolder[] = [
  {
    id: TicketsFolderId.All,
    statusSet: [TicketStatus.Open, TicketStatus.InProgress],
    folderName: 'All Active',
    folderTipTitle: 'New and In Progress',
    folderTipText: 'New and In Progress tickets',
    icon: AllInboxIcon,
    hasCounter: true,
  },
  {
    id: TicketsFolderId.Open,
    statusSet: [TicketStatus.Open],
    folderName: TicketStatusNames[TicketStatus.Open],
    folderTipTitle: 'New: (Requires Attention)',
    folderTipText:
      "Indicates that no action has been taken on the ticket. Once a New ticket's status has been changed, it can never be set back to New.",
    icon: EmailIcon,
    hasCounter: true,
  },
  {
    id: TicketsFolderId.Draft,
    statusSet: [TicketStatus.Draft],
    folderName: TicketStatusNames[TicketStatus.Draft],
    folderTipTitle: 'Draft',
    folderTipText: 'A ticket being preapred for publication',
    icon: DriveFileRenameOutlineIcon, //DraftsIcon,
    italic: true,
    hasCounter: true,
  },
  {
    id: TicketsFolderId.InProgress,
    statusSet: [TicketStatus.InProgress],
    folderName: TicketStatusNames[TicketStatus.InProgress],
    folderTipTitle: 'In Progress: (Under Review)',
    folderTipText:
      'Indicates a ticket has been assigned to a user and is in progress. It is waiting for action by the user, When the assigned rep reads and begins working on the ticket they should update the ticket to this status.',
    icon: RotateRightIcon,
    hasCounter: true,
  },
  {
    id: TicketsFolderId.InProgress24,
    statusSet: [TicketStatus.InProgress],
    folderName: '> 24hrs',
    folderTipTitle: 'In Progress more than 24 hours: (Under Review)',
    folderTipText: 'In Progress more than 24 hours: (Under Review)',
    icon: Update,
    indent: true,
    filterKey: FilterKey.moreThen24h,
    hasCounter: true,
  },
  {
    id: TicketsFolderId.Closed,
    statusSet: [TicketStatus.Closed],
    folderName: TicketStatusNames[TicketStatus.Closed],
    folderTipTitle: 'Resolved: (Completed)',
    folderTipText:
      'Indicates the user has submitted a solution. Once the ticket has been resolved, the user who opened the ticket will have access to see the resolution notes and will receive a notification in their portal',
    icon: CheckCircleIcon, //CheckCircleOutlineIcon, //AssignmentTurnedInIcon,
    hasCounter: false,
  },
  {
    id: TicketsFolderId.Unresolved,
    statusSet: [TicketStatus.Unresolved],
    folderName: TicketStatusNames[TicketStatus.Unresolved],
    folderTipTitle: 'Unresolved: (Unable to Complete)',
    folderTipText: 'Indicates the user was unable to complete the ticket',
    icon: HighlightOffIcon, //CancelIcon, //DoNotDisturbIcon,
    hasCounter: false,
  },
  {
    id: TicketsFolderId.Deleted,
    statusSet: [TicketStatus.Deleted],
    folderName: TicketStatusNames[TicketStatus.Deleted],
    folderTipTitle: 'Deleted',
    folderTipText: 'Deleted',
    icon: FolderDeleteIcon,
    hasDivider: true,
    hasCounter: false,
  },
];
