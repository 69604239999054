import {
  Collections,
  NotificationCategory,
  NotificationView,
  selectNotificationView,
  useCallable,
  useNotification,
} from '@ozark/common';
import {addMinutes} from 'date-fns';
import firebase from 'firebase/compat';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {Firebase} from '../../../firebase';
import {useInfiniteData, useInfiniteSnapshotsFromRef} from '../../../hooks';
import {getNotificationDetailsRoute} from '../../NotificationsPage/routes';
import {useNotificationsState} from '../hooks/useNotificationsState';

const getImportantNotificationsDocsFilter = (
  query: firebase.firestore.Query<firebase.firestore.DocumentData>
): firebase.firestore.Query<firebase.firestore.DocumentData> => {
  return query.where('status', '==', 'new').where('isImportantNotification', '==', true);
};

export const useImportantNotifications = (
  userUid: string | undefined,
  isMarkingAsRead: boolean
) => {
  const [importantNotifications, setImportantNotifications] = useState<NotificationView[]>([]);

  const notificationsCollectionRef = useMemo(
    () =>
      userUid
        ? Firebase.firestore
            .collection(Collections.userNotifications)
            .doc(userUid)
            .collection(Collections.notifications)
        : null,
    [userUid]
  );

  const {documents: importantNotificationDocuments, next} = useInfiniteSnapshotsFromRef(
    notificationsCollectionRef,
    selectNotificationView,
    {
      limit: 10,
      order: 'desc',
      orderBy: 'createdAt',
      filter: getImportantNotificationsDocsFilter,
    }
  );

  useEffect(() => {
    if (isMarkingAsRead) {
      // while isMarkingAsRead is processing, updating importantNotifications is blocked
      return;
    }

    setImportantNotifications(Object.values(importantNotificationDocuments.data ?? {}));
  }, [importantNotificationDocuments, isMarkingAsRead]);

  const onLoadMore = useCallback(() => {
    const lastNotificationInList = importantNotifications[importantNotifications.length - 1];

    if (lastNotificationInList) {
      next({startAfter: lastNotificationInList?.ref});
    }
  }, [importantNotifications, next]);

  const loadMoreRef = useInfiniteData(onLoadMore, importantNotificationDocuments.hasNextPage);

  return {
    importantNotificationDocuments,
    importantNotifications,
    loadMoreRef,
  };
};

export enum SnoozeTime {
  fiveMinutes = '5 minutes',
  fifteenMinutes = '15 minutes',
  thirtyMinutes = '30 minutes',
  sixtyMinutes = '60 minutes',
  tomorrow = 'Tomorrow',
}

const snoozeTimeMap: {[key in SnoozeTime]: number} = {
  [SnoozeTime.fiveMinutes]: 5,
  [SnoozeTime.fifteenMinutes]: 15,
  [SnoozeTime.thirtyMinutes]: 30,
  [SnoozeTime.sixtyMinutes]: 60,
  [SnoozeTime.tomorrow]: 1440, // 24 hours * 60 minutes
};

export const useSnoozeImportantNotifications = (userUid?: string, userCollection?: string) => {
  const location = useLocation();
  const [anchorSnoozeMenuElement, setAnchorSnoozeMenuElement] = useState<null | HTMLElement>(null);
  const openSnoozeMenu = Boolean(anchorSnoozeMenuElement);
  const [snoozeNotifications, setSnoozeNotifications] = useState<{
    isSnooze: boolean;
    date?: Date;
  } | null>(null);

  const handleOpenSnoozeMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorSnoozeMenuElement(event.currentTarget);
  };
  const handleCloseSnoozeMenu = () => setAnchorSnoozeMenuElement(null);

  // load snoozeNotificationsUntil time
  useEffect(() => {
    if (!userCollection) return;

    const unsubscribe = Firebase.firestore
      .collection(userCollection)
      .doc(userUid)
      .onSnapshot(
        snapshot => {
          const data = snapshot.data();
          if (data && data.snoozeNotificationsUntil) {
            const snoozeUntil = new Date(data.snoozeNotificationsUntil.seconds * 1000);
            setSnoozeNotifications({isSnooze: snoozeUntil > new Date(), date: snoozeUntil});
          } else {
            setSnoozeNotifications({isSnooze: false});
          }
        },
        err => {
          console.error(err);
        }
      );
    return () => {
      unsubscribe();
    };
  }, [userUid, userCollection, location]);

  const saveSnooze = (value: SnoozeTime) => {
    setAnchorSnoozeMenuElement(null);
    if (!userCollection) return;

    const snoozeUntil = addMinutes(new Date(), snoozeTimeMap[value]);

    Firebase.firestore
      .collection(userCollection)
      .doc(userUid)
      .set({snoozeNotificationsUntil: Firebase.Timestamp.fromDate(snoozeUntil)}, {merge: true})
      .then(() => {
        setSnoozeNotifications({isSnooze: true, date: snoozeUntil});
      })
      .catch(e => {
        console.error(e);
        return;
      });
  };

  return {
    openSnoozeMenu,
    snoozeNotifications,
    handleOpenSnoozeMenu,
    handleCloseSnoozeMenu,
    saveSnooze,
    anchorSnoozeMenuElement,
  };
};

export const useImportantNotificationsActions = () => {
  const history = useHistory();
  const {markRead} = useNotificationsState();
  const [isMarkingAsRead, setIsMarkingAsRead] = useState(false);
  const {markAllNotificationsAsRead} = useCallable();
  const showNotification = useNotification();

  const markAllImportantNotificationsAsRead = async () => {
    try {
      setIsMarkingAsRead(true);

      const result = await markAllNotificationsAsRead({
        isImportantNotificationsOnly: true,
      });
      if (result.status === 'error') {
        throw new Error('Error in markAllNotificationsAsRead callable function');
      }
    } catch (error) {
      showNotification('error', 'Failed to mark important notifications as read');
      console.error('Failed to mark important notifications as read with an error:', error);
    } finally {
      setIsMarkingAsRead(false);
    }
  };

  const handleNotificationClick = ({id, link, category}: NotificationView) => {
    markRead(id);

    if (category === NotificationCategory.Announcements) {
      history.push(getNotificationDetailsRoute(id));
      return;
    }

    if (!link) {
      return;
    }

    if (link.startsWith('/')) {
      history.push(link);
    } else {
      window.open(link, '_blank', 'noopener');
    }
  };

  return {
    isMarkingAsRead,
    markAllImportantNotificationsAsRead,
    handleNotificationClick,
  };
};
