export enum DispositionStatisticFields {
  agent = 'agent',
  count = 'count',
  countOfTotal = 'countOfTotal',
  volume = 'volume',
  volumeOfTotal = 'volumeOfTotal',
}

export type DispositionStatistic = {
  agent: string;
  count: number;
  countOfTotal: number;
  volume: number;
  volumeOfTotal: number;
};
