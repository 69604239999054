import {yupResolver} from '@hookform/resolvers/yup';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  Agent,
  Application,
  Collections,
  createAuditNonce,
  Firebase,
  useAgents,
  useGroups,
  ViewBase,
} from '@ozark/common';
import {Loading, Select} from '@ozark/common/components';
import {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {useNotification} from '../../hooks/useNotification';
import {useStore} from '../../store/helpers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      [theme.breakpoints.up('md')]: {
        width: 400,
      },
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

export interface EditAgentDialogDataModel {
  applicationId: string;
  groupId: string;
  agentId?: string;
}

export const EditGroupAgentFormSchema = yup.object().shape({
  groupId: yup.string().required('Group is required'),
  agentId: yup.string().required('Agent is required'),
});

type Props = {
  dialogData: EditAgentDialogDataModel;
  onClose: () => void;
};

export const EditAgentDialog = ({dialogData, onClose}: Props) => {
  const classes = useStyles();
  const {authUser} = useStore();
  const [loading, setLoading] = useState(false);
  const showNotification = useNotification();
  const [filteredAgents, setFilteredAgents] = useState<ViewBase<Agent>[]>([]);

  const defaultValues: any = {
    groupId: dialogData.groupId,
    agentId: dialogData.agentId ?? 'null',
  };

  const hookForm = useForm({
    defaultValues,
    resolver: yupResolver(EditGroupAgentFormSchema),
  });

  const {
    formState: {errors},
    control,
    handleSubmit,
    watch,
    setValue,
  } = hookForm;

  const {documents: groups} = useGroups();
  const {documents: agents} = useAgents();

  const watchGroupId = watch('groupId', dialogData.groupId);

  const onSuccess = async (formData: any) => {
    try {
      setLoading(true);

      const ref = Firebase.firestore
        .collection(Collections.applications)
        .doc(dialogData.applicationId);

      const auditNonce = createAuditNonce(authUser.data!.uid);

      const applicationDoc = await ref.get();

      if (!applicationDoc) {
        showNotification('error', 'Failed to update Group & Agent.');
        return;
      }

      const application = applicationDoc.data() as Application;

      const groupDocument =
        groups && groups.data && groups.data.find(g => g.id === formData.groupId);

      if (!groupDocument) {
        showNotification('error', 'Failed to update Group & Agent.');
        return;
      }

      if (application.merchantUid && application.group.id !== groupDocument.id) {
        showNotification(
          'error',
          'Cannot reassign the group of an application with a registered merchant.'
        );
        return;
      }

      const group = groupDocument && {
        id: groupDocument.id,
        name: groupDocument.name,
        logoUrl: groupDocument.logoUrl,
      };

      let agent = formData.agentId;

      if (agent) {
        const agentDocument =
          agents && agents.data && agents.data.find(a => a.id === formData.agentId);
        agent =
          (agentDocument && {
            id: agentDocument.id,
            firstName: agentDocument.firstName,
            lastName: agentDocument.lastName,
          }) ??
          null;
      }
      const requestData = {
        group,
        agent,
      };

      await ref.set({...requestData, auditNonce, updatedAt: new Date()}, {merge: true});
      showNotification('success', 'Group & Agent successfully updated.');
      onClose();
      return;
    } catch (_err) {
      showNotification('error', 'Failed to update Group & Agent.');
    } finally {
      setLoading(false);
    }
  };

  const onError = (data: any) => {
    console.error(`Error updating Group & Agent ${JSON.stringify(data)}`);
    setLoading(false);
  };

  useEffect(() => {
    if (!agents.data) {
      return;
    }

    const filteredAgents = agents.data.filter(
      (agent: Agent) => agent.group && agent.group.id === watchGroupId && !agent.deleted
    );
    setFilteredAgents(filteredAgents);
    setValue('agentId', '' as any);
  }, [agents, watchGroupId, setValue]);

  const generateGroupOptions = () =>
    groups.data?.sortAndMap(
      group => <MenuItem value={group.id}>{group.name}</MenuItem>,
      group => group.name
    );

  const generateAgentOptions = () => [
    <MenuItem value={'null'}>Unassigned</MenuItem>,
    <Divider />,
    filteredAgents.sortAndMap(
      agent => (
        <MenuItem value={agent.id}>
          {agent.firstName} {agent.lastName}
        </MenuItem>
      ),
      agent => `${agent.firstName} ${agent.lastName}`
    ),
  ];

  if (agents.promised || groups.promised) {
    return <Loading />;
  }

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="edit-group-dialog-title" maxWidth={'lg'}>
      <DialogTitle id="edit-group-dialog-title">Edit Group/Agent:</DialogTitle>
      <DialogContent dividers>
        <div className={classes.content}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Select
                variant="outlined"
                name="groupId"
                label="Group"
                errors={errors}
                control={control}
              >
                {generateGroupOptions()}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <Select
                variant="outlined"
                name="agentId"
                label="Agent"
                required
                displayEmpty
                errors={errors}
                control={control}
              >
                {generateAgentOptions()}
              </Select>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit(onSuccess, onError)} color="primary" disabled={loading}>
          {loading && <CircularProgress className={classes.buttonProgress} size={24} />}
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
