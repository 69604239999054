import {AgentView} from '@ozark/functions/src/documents';
import {useEffect, useState} from 'react';
import {AsyncState} from '../../models';
import {AutoCompleteInputBase} from '../AutoComplete';

type Props = {
  onAgentSelect: (agent?: AgentView) => void;
  agents: AsyncState<AgentView[]>;
  selectedAgent?: AgentView;
};

export const AgentSelect = ({agents, onAgentSelect, selectedAgent}: Props) => {
  const [activeAgents, setActiveAgents] = useState<AgentView[]>([]);

  useEffect(() => {
    if (!agents.data) {
      return;
    }

    setActiveAgents(agents.data.filter(agent => agent.isActive));
  }, [agents]);

  const getDropDownTitle = (agent?: AgentView): string =>
    agent ? `${agent?.firstName || ''} ${agent?.lastName || ''}`.trim() : 'All Agents';

  return (
    <AutoCompleteInputBase
      selected={selectedAgent}
      setSelected={onAgentSelect}
      icon
      placeholder="Select agent..."
      width={200}
      options={activeAgents}
      getOptionLabel={(agent: AgentView) => getDropDownTitle(agent)}
      onItemSelect={(agent: AgentView | null) => onAgentSelect(agent ?? undefined)}
    />
  );
};
