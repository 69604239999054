export enum PdfDocuments {
  BancorpTSYS = 'tpl_5EYnQHrEH7yzNe3Zhh',
  FirstData = 'tpl_YpS2pD4mZyy96FM4Jr',
  AvidiaTSYS = 'tpl_NpTQxeqFLLHeYZHAJt',
  AvidiaFirstData = 'tpl_qtCpHqRNbk373DZhAJ',
  EvolveApplication = 'tpl_S6ebxYqLxCL2DHcCkD',
  EvolveTSYS = 'tpl_qYSnEJC453qghMnHm3',
  ChesapeakeTSYS = 'tpl_ZzCXZXF5PyargKaz9G',
  PathlyApplication = 'tpl_tfqG5bZqj93s6qHGk9',
  AppsSynovus = 'tpl_aqnrC9pFREQqdqkQJp',
}

export enum PdfTemplates {
  DisputeResponse = 'tpl_rrjALYkKr6eMgsk5b5',
  SingleTextFieldDocument = 'tpl_cYffgXPPd776GnPD7x',
}
