import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import MessageIcon from '@mui/icons-material/Message';
import TagIcon from '@mui/icons-material/Tag';
import {
  Box,
  CardActionArea,
  Chip,
  Divider,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {
  Ticket,
  TicketPriority,
  TicketStatus,
  TicketStatusName,
  UniversalTimestamp,
  ViewableByType,
} from '@ozark/functions/src/documents';
import {formatDistanceToNow} from 'date-fns';
import React, {ReactNode} from 'react';
import {useHistory} from 'react-router-dom';
import {getAssigneeName, getAssociationName, prepareTextPreview} from '../../..';
import {useUserInfo} from '../../../hooks';
import {useLastCommentAuthor} from '../hooks/useLastCommentAuthor';
import {useLastCommentInfoToDisplay} from '../hooks/useLastCommentInfoToDisplay';
import {useTicketAuthor} from '../hooks/useTicketAuthor';

const ticketTimeScope = [
  TicketStatus.Draft,
  TicketStatus.Open,
  TicketStatus.InProgress,
  TicketStatus.Closed,
  TicketStatus.Unresolved,
];
const ticketTimeElapsedScope = [
  TicketStatus.Open,
  TicketStatus.InProgress,
  TicketStatus.Closed,
  TicketStatus.Unresolved,
];

type Props = {
  ticket: Ticket;
};

// trafic lights colors
const COLOR_DEFAULT = '#e0e0e0';
const COLOR_YELLOW = '#fbc02d'; //#ffe082
const COLOR_BLUE = '#34ADF7';
const COLOR_RED = '#d32f2f';
const COLOR_GREEN = '#4caf50';

const getTicketColor = (status?: TicketStatus) => {
  switch (status) {
    case TicketStatus.Open:
      return COLOR_YELLOW;
    case TicketStatus.InProgress:
      return COLOR_BLUE;
    case TicketStatus.Closed:
      return COLOR_GREEN;
    case TicketStatus.Unresolved:
      return COLOR_RED;
    default:
      return COLOR_DEFAULT;
  }
};

export const TicketsListCard = ({ticket}: Props) => {
  const history = useHistory();
  const {isErpUser} = useUserInfo();
  const ticketLastCommentInfo = useLastCommentInfoToDisplay(ticket);
  const lastCommentInfoAuthor = useLastCommentAuthor(ticketLastCommentInfo);
  const assigneeName = getAssigneeName(ticket.assignee, isErpUser);
  const {getAuthorName} = useTicketAuthor();
  const authorName = getAuthorName(ticket.author);
  const colorStatus = getTicketColor(ticket.status);
  const colorPriority = ticket.priority === TicketPriority.High ? COLOR_RED : COLOR_BLUE;

  const showTicketTimeElapsed = ticket.status && ticketTimeElapsedScope.includes(ticket.status);
  const ticketTimeElapsed =
    ticket.openedAt &&
    formatDistanceToNow((ticket.openedAt as UniversalTimestamp)?.toDate(), {addSuffix: false});

  const showLastCommentInfo = ticketLastCommentInfo;
  const lastCommentInfoElapsed =
    ticketLastCommentInfo &&
    ticketLastCommentInfo.createdAt &&
    formatDistanceToNow((ticketLastCommentInfo.createdAt as UniversalTimestamp)?.toDate(), {
      addSuffix: false,
    });
  const lastCommentInfo = lastCommentInfoAuthor
    ? `${lastCommentInfoElapsed ?? ''} (by ${lastCommentInfoAuthor ?? ''})`
    : `${lastCommentInfoElapsed ?? ''}`;

  const showTicketTime = ticket.status && ticketTimeScope.includes(ticket.status);
  const isDraft = ticket.status === TicketStatus.Draft;
  const ticketTime = isDraft
    ? (ticket.createdAt as UniversalTimestamp)?.toDate().toLocaleString()
    : (ticket.openedAt as UniversalTimestamp)?.toDate().toLocaleString();

  const ticketTimeTitle = isDraft ? 'Created at' : 'Opened at';

  const associationInfo = getAssociationName(ticket.association, isErpUser);
  const handleLinkClick: React.MouseEventHandler<HTMLAnchorElement> = e => {
    e.preventDefault();
    const anchor = (e.target as HTMLElement).closest('a') as HTMLAnchorElement | null;
    if (!anchor) return;
    const href = anchor.getAttribute('href');
    href && history.push(href);
  };
  return (
    <Card sx={{borderLeft: `solid 8px ${colorStatus}`, ml: 2}}>
      <CardActionArea href={`/tickets/id/${ticket.id}`} onClick={handleLinkClick}>
        <Box display="flex" pl={2} pr={1} pt={1} alignItems="center">
          <Typography
            variant="h6"
            sx={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              pr: 1,
            }}
          >
            {ticket.subject || '[No Title]'}
          </Typography>
        </Box>
        <Box px={2} display="flex">
          <Typography
            sx={{
              color: '#00000099',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              flex: 1,
            }}
          >
            {prepareTextPreview(ticket.description) || 'No description available'}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" pt={0.5} pb={1} px={2}>
          <Chip
            label={ticket.status && TicketStatusName[ticket.status]}
            variant="outlined"
            size="small"
            sx={{color: colorStatus, borderColor: colorStatus, mr: 1}}
          />
          {ticket.category && (
            <Chip
              label={`${ticket.category?.type?.name}: ${ticket.category?.category?.name}`}
              variant="outlined"
              size="small"
              icon={<TagIcon />}
              sx={{mr: 1}}
            />
          )}
          <Box flex={1} />
          {showTicketTime && ticketTime && (
            <>
              <Box display="flex" alignItems="center">
                {showLastCommentInfo && (
                  <Box display="flex" alignItems="center" mr={2}>
                    <MessageIcon color="primary" fontSize="small" />
                    &nbsp;
                    <KeyStyled>{lastCommentInfo}</KeyStyled>
                  </Box>
                )}
                {showTicketTimeElapsed && ticketTimeElapsed && (
                  <Box display="flex" alignItems="center">
                    <AccessTimeIcon color="primary" fontSize="small" />
                    &nbsp;
                    <KeyStyled>{ticketTimeElapsed}</KeyStyled>
                  </Box>
                )}
                <Box display="flex" alignItems="center" ml={2}>
                  <CalendarTodayIcon color="primary" fontSize="small" />
                  &nbsp;
                  <KeyStyled>{ticketTimeTitle}&nbsp;&nbsp;</KeyStyled>
                  <KeyStyled>{ticketTime}</KeyStyled>
                </Box>
              </Box>
            </>
          )}
        </Box>
        <Divider />
        <CardContent sx={{py: 1}}>
          <Box></Box>
          <Table size="small" style={{tableLayout: 'fixed'}}>
            <TableBody>
              <TableRow sx={{'& td, & th': {border: 0}}}>
                <TableCell component="th" scope="row" sx={{pl: 0, pb: 0, width: '23%'}}>
                  <KeyStyled>Assignee:</KeyStyled>
                </TableCell>
                <TableCell component="th" scope="row" sx={{pl: 0, pb: 0}}>
                  <KeyStyled>Association:</KeyStyled>
                </TableCell>
                {isErpUser && (
                  <TableCell component="th" scope="row" sx={{pl: 0, pb: 0}}>
                    <KeyStyled>Viewable By:</KeyStyled>
                  </TableCell>
                )}
                <TableCell component="th" scope="row" sx={{pl: 0, pb: 0}}>
                  <KeyStyled>Creator:</KeyStyled>
                </TableCell>
                <TableCell component="th" scope="row" sx={{pl: 0, pb: 0}}>
                  <KeyStyled>Priority:</KeyStyled>
                </TableCell>
                <TableCell component="th" scope="row" sx={{pl: 0, pb: 0}}>
                  <KeyStyled>Labels:</KeyStyled>
                </TableCell>
                <TableCell component="th" scope="row" sx={{pl: 0, pb: 0, width: '10%'}}>
                  <KeyStyled>ID:</KeyStyled>
                </TableCell>
              </TableRow>
              <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                <TableCell component="th" scope="row" sx={{pl: 0, pt: 0}}>
                  <TypographyValue
                    variant="body1"
                    color={assigneeName ? undefined : 'error'}
                    title={assigneeName ? `${assigneeName} - ${ticket?.assignee?.type}` : undefined}
                  >
                    {assigneeName ?? ticket?.assignee?.type ? (
                      <span>
                        {assigneeName}, <i style={{color: '#666666'}}>({ticket?.assignee?.type})</i>
                      </span>
                    ) : (
                      'Unassigned'
                    )}
                  </TypographyValue>
                </TableCell>
                <TableCell component="th" scope="row" sx={{pl: 0, pt: 0}}>
                  <TypographyValue variant="body1" title={associationInfo.name}>
                    {associationInfo.name}
                  </TypographyValue>
                </TableCell>
                {isErpUser && (
                  <TableCell component="th" scope="row" sx={{pl: 0, pt: 0}}>
                    <TypographyValue variant="body1">
                      {ticket.viewableBy ?? ViewableByType.all}
                    </TypographyValue>
                  </TableCell>
                )}
                <TableCell component="th" scope="row" sx={{pl: 0, pt: 0}}>
                  <TypographyValue variant="body1">{authorName}</TypographyValue>
                </TableCell>
                <TableCell component="th" scope="row" sx={{pl: 0, pt: 0}}>
                  <Typography variant="body1" color={colorPriority}>
                    {ticket.priority}
                  </Typography>
                </TableCell>
                <TableCell component="th" scope="row" sx={{pl: 0, pt: 0}}>
                  {ticket.labels?.map(l => (
                    <Chip size="small" key={l} label={l} sx={{mr: 1, mb: 0.5}} />
                  )) ?? '...'}
                </TableCell>
                <TableCell component="th" scope="row" sx={{pl: 0, pt: 0, width: '10%'}}>
                  <TypographyValue variant="body1">
                    {ticket?.distinguishableId ?? '...'}
                  </TypographyValue>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

const TypographyValue = styled(Typography)`
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const KeyStyled = ({children}: {children: ReactNode}) => {
  return (
    <Typography component="div" color="GrayText" variant="body2" sx={{whiteSpace: 'nowrap'}}>
      {children}
    </Typography>
  );
};
