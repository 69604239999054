import {ApiBase, SearchCriteria} from '@ozark/common';
import {
  AgentChargebacks,
  AgentDeclines,
  AgentsSales,
  DispositionStatistic,
} from '@ozark/functions/src/functions/express/private/types';
import {ContextKey} from '@ozark/functions/src/functions/express/private/types/Context';
import {PaginatedResponse} from '@ozark/functions/src/shared';
import {ActiveFilter} from '../components';
import {toQuery} from '../util/FilterUtil';

export class AgentStatisticsApi extends ApiBase {
  getAgentsStatisticsSales = (pageInfo: SearchCriteria, filters?: ActiveFilter[]) => {
    const queryString = toQuery(pageInfo, filters);
    return this.get<AgentsSales | null>(`agent-statistics/sales?${queryString}`);
  };

  getDispositionStatistics = (
    searchCriteria: SearchCriteria,
    disposition: string,
    agentId?: string
  ) => {
    const queryString = toQuery(searchCriteria);
    return this.post<PaginatedResponse<DispositionStatistic>>(
      `agent-statistics/dispositions?${queryString}`,
      {
        [ContextKey.AgentId]: agentId,
        disposition: disposition,
      }
    );
  };

  getAgentsStatisticsChargebacks = (
    pageInfo: SearchCriteria,
    filters?: ActiveFilter[],
    agentId?: string
  ) => {
    const queryString = toQuery(pageInfo, filters);
    return this.get<AgentChargebacks | null>(
      `agent-statistics/chargebacks?${queryString}&agentId=${agentId || ''}`
    );
  };

  getAgentsDeclinesStatistics = (
    pageInfo: SearchCriteria,
    filters?: ActiveFilter[],
    agentId?: string
  ) => {
    const queryString = toQuery(pageInfo, filters);
    return this.get<AgentDeclines | null>(
      `agent-statistics/declines?${queryString}&agentId=${agentId || ''}`
    );
  };
}
