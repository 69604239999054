import {
  Collections,
  NotificationStatus,
  NotificationView,
  selectNotificationView,
  UniversalSnapshot,
} from '@ozark/common';
import {useEffect, useRef, useState} from 'react';
import {Firebase} from '../../../firebase';
import {AsyncState} from '../../../hooks/AsyncState';
import NotificationSound from '../../../static/media/notification-sound.mp3';
import {useAuthContainer} from '../../../store';
import {callOnceAcrossTabs} from '../../../util/TabsUtil';
import {useNotificationsState} from '../hooks/useNotificationsState';
import {FaviconBadger} from './FaviconBadge';

const faviconBadger = new FaviconBadger();

const audio = new Audio(NotificationSound);

const TABS_NOTIFICATIONS_CHANNEL = 'notifications-channel';
// TODO: When Layout won't be rerendered on each react-router redirect,
//  move creating new broadcast channel to useEffect and closing this channel on unmount.
//  At the moment we can't move it to useEffect because this component is rerendering on each router action and
//  it causes performance issues on each react-router action
const notificationsChannel = new BroadcastChannel(TABS_NOTIFICATIONS_CHANNEL);

enum TABS_MESSAGE {
  TRY_TO_PLAY_SOUND = 'TRY_TO_PLAY_SOUND',
}

export const NotificationsTabIndicator = () => {
  const {authUser} = useAuthContainer();
  const {unreadCount: unseenCount} = useNotificationsState();
  const [lastNotification, setLastNotification] = useState<
    AsyncState<NotificationView | undefined>
  >({promised: false});
  const prevLastNotification = useRef<AsyncState<NotificationView | undefined>>();
  const alreadyTriedToPlaySoundNotificationIds = useRef(new Set());

  useEffect(() => {
    faviconBadger.value = unseenCount;

    return () => {
      faviconBadger.value = undefined;
    };
  }, [unseenCount]);

  useEffect(() => {
    if (!authUser.data?.uid) {
      return;
    }

    setLastNotification({promised: true});

    return Firebase.firestore
      .collection(Collections.userNotifications)
      .doc(authUser.data?.uid)
      .collection(Collections.notifications)
      .orderBy('createdAt', 'desc')
      .limit(1)
      .onSnapshot(snapshot => {
        if (snapshot.empty) {
          setLastNotification({promised: false});
          return;
        }

        const [lastNotificationDoc] = snapshot.docs;
        setLastNotification({
          promised: false,
          data: selectNotificationView(lastNotificationDoc as UniversalSnapshot<NotificationView>),
        });
      });
  }, [authUser.data?.uid]);

  const tryToPlaySound = (notificationId: string) => {
    callOnceAcrossTabs(notificationId, unlock => {
      (audio.cloneNode(true) as HTMLAudioElement).play().catch(() => {
        unlock();
        console.log(
          'Failed to play sound. Seems it was blocked by browser policy. Trying to message another tab to play sound'
        );
        alreadyTriedToPlaySoundNotificationIds.current.add(notificationId);
        notificationsChannel.postMessage({
          type: TABS_MESSAGE.TRY_TO_PLAY_SOUND,
          notificationId,
        });
      });
    });
  };

  useEffect(() => {
    const onMessage = (message: {data: any}) => {
      if (
        message?.data.type === TABS_MESSAGE.TRY_TO_PLAY_SOUND &&
        !alreadyTriedToPlaySoundNotificationIds.current.has(message.data.notificationId)
      ) {
        tryToPlaySound(message.data.notificationId);
      }
    };

    notificationsChannel.addEventListener('message', onMessage);

    return () => {
      notificationsChannel.removeEventListener('message', onMessage);
    };
  }, []);

  useEffect(() => {
    if (lastNotification.promised || !lastNotification.data) {
      prevLastNotification.current = lastNotification;
      return;
    }

    const isNewNotificationReceived =
      prevLastNotification.current?.data?.id !== lastNotification.data?.id &&
      lastNotification.data?.status === NotificationStatus.new;
    const isFirstLoading = prevLastNotification.current?.promised && !lastNotification.promised;

    const notificationId = lastNotification.data.id;
    if (isNewNotificationReceived && !isFirstLoading && notificationId) {
      tryToPlaySound(notificationId);
    }
    prevLastNotification.current = lastNotification;
  }, [lastNotification]);

  return null;
};
