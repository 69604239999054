import {Dispositions} from '@ozark/common';
import {CreateDisputeReasonProps, DisputeReason} from '../../ApplicationDisputeHandlers';
import {createDisputeReason} from '../createDisputeReason';

export const withdrawn = {
  [Dispositions.asWithdrawn]: [
    {
      name: 'Agent or Merchant',
      description: `This application has been withdrawn per the agent or merchant’s request`,
      type: 'checkbox',
    },
    {
      name: 'Timeframe',
      description:
        'This application has been withdrawn for pending over 30 days. Please resubmit once ready to resume the review process',
      type: 'checkbox',
    },
    {
      name: 'Other Reason',
      description: 'Other Reason',
      type: 'rich-text',
    },
  ].map(reason => {
    const {name, description, type} = reason as CreateDisputeReasonProps;
    return createDisputeReason({
      name: name,
      description: description,
      type: type,
    });
  }) as DisputeReason[],
  [Dispositions.uwWithdrawn]: [
    {
      name: 'Agent or Merchant',
      description: `This application has been withdrawn per the agent or merchant’s request`,
      type: 'checkbox',
    },
    {
      name: 'Timeframe',
      description:
        'This application has been withdrawn for pending over 30 days. Please resubmit once ready to resume the review process',
      type: 'checkbox',
    },
    {
      name: 'Other Reason',
      description: 'Other Reason',
      type: 'rich-text',
    },
  ].map(reason => {
    const {name, description, type} = reason as CreateDisputeReasonProps;
    return createDisputeReason({
      name: name,
      description: description,
      type: type,
    });
  }) as DisputeReason[],
} as {[key in Dispositions]?: DisputeReason[]};
