import {Menu, MenuItem, Tooltip, Typography} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {ListOption, TooltipPlacement} from '@ozark/common';
import React, {Fragment, useEffect, useState} from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typoStyle: {
      cursor: 'pointer',
      display: 'inherit',
    },
  })
);

interface Props {
  value: string;
  setValue: (val: string) => Promise<void> | void;
  noSort?: boolean;
  className?: string;
  options: ListOption[];
  empty?: React.ReactNode | string;
  tooltip?: string;
  tooltipPlacement?: TooltipPlacement;
}

export const InlineSelect = ({
  value,
  setValue,
  noSort = false,
  className = '',
  options,
  empty = <Fragment>&nbsp;</Fragment>,
  tooltip = 'Click to change',
  tooltipPlacement = TooltipPlacement.Bottom,
}: Props) => {
  const classes = useStyles();
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [inputValue, setInputValue] = useState<string>(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleOnClick = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const onSelect = async (val: string) => {
    // save value and close the editor
    setInputValue(val);
    handleMenuClose();
    await setValue(val);
  };

  const getOptions = () => {
    const getMenuItem = (key: string, value: string) => (
      <MenuItem className={className} key={key} value={value} onClick={() => onSelect(value)}>
        {value}
      </MenuItem>
    );

    return noSort
      ? options.map(opt => getMenuItem(opt.key, opt.value))
      : options.sortAndMap(
          opt => getMenuItem(opt.key, opt.value),
          opt => opt.value
        );
  };

  return (
    <Fragment>
      <Tooltip title={tooltip} placement={tooltipPlacement}>
        <Typography
          onClick={handleOnClick}
          className={`${classes.typoStyle} ${className}`}
          noWrap={true}
          variant="caption"
        >
          {inputValue?.length ? inputValue : empty}
        </Typography>
      </Tooltip>
      <Menu
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
      >
        {getOptions()}
      </Menu>
    </Fragment>
  );
};
