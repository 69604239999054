import {Context} from '@ozark/functions/src/functions/express/private/types';
import {AxiosInstance, AxiosRequestConfig, CancelToken, CancelTokenStatic} from 'axios';

type ApiResponse<TData> = {
  status: string;
  data: TData;
};

export abstract class ApiBase {
  protected instance: AxiosInstance;
  protected cancelToken: CancelTokenStatic;

  constructor(axiosInstance: AxiosInstance, cancelToken: CancelTokenStatic) {
    this.instance = axiosInstance;
    this.cancelToken = cancelToken;
  }

  getAxiosRequestConfig = (context?: Context, cancelToken?: CancelToken) => {
    let config: AxiosRequestConfig = {};
    if (context) {
      config = {
        headers: {
          context: btoa(JSON.stringify(context)),
        },
        cancelToken,
      };
    }
    return config;
  };

  protected async post<TResponse>(
    url: string,
    body: any,
    context?: Context,
    cancelToken?: CancelToken
  ): Promise<TResponse> {
    try {
      const {data} = await this.instance.post<ApiResponse<TResponse>>(
        url,
        body,
        this.getAxiosRequestConfig(context, cancelToken)
      );
      if (data.status !== 'ok') throw Error('error making post request');
      return data.data as TResponse;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  protected async get<TResponse>(
    url: string,
    context?: Context,
    cancelToken?: CancelToken
  ): Promise<TResponse | null> {
    try {
      const {data} = await this.instance.get<ApiResponse<TResponse>>(
        url,
        this.getAxiosRequestConfig(context, cancelToken)
      );
      if (data.status !== 'ok') throw Error('error making get request');
      return data.data as TResponse;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public getCancelTokenSource() {
    return this.cancelToken.source();
  }
}
