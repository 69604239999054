import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import {DefaultComponentProps, OverridableTypeMap} from '@mui/material/OverridableComponent';
import {ReasonInputType} from '@ozark/common/';
import camelcase from 'lodash/camelCase';
import {MenuProps} from '../../helpers';
import {CreateDisputeReasonProps, DisputeReason} from './index';

export const createCheckboxListReason = ({
  name,
  description,
  params,
}: CreateDisputeReasonProps): DisputeReason => ({
  name: camelcase(name),
  title: name,
  inputType: ReasonInputType.CheckboxList,
  descriptionHtml: description,
  Description: (props: DefaultComponentProps<OverridableTypeMap>) => (
    <Typography {...props}>
      <span dangerouslySetInnerHTML={{__html: params?.label ?? ''}} />
    </Typography>
  ),
  FieldInput: props => (
    <FormControl fullWidth variant="outlined">
      <Select
        name={name}
        variant="outlined"
        labelId={`${name}-label`}
        id={name}
        multiple
        input={<OutlinedInput notched />}
        renderValue={selected => (selected as string[]).join(', ')}
        fullWidth
        MenuProps={MenuProps}
        {...props}
      >
        {params?.values?.map(value => (
          <MenuItem key={value} value={value}>
            <Checkbox checked={props.value.indexOf(value) > -1} color="primary" />
            <ListItemText primary={value} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  ),
});
